const developSubstring = "throw-log-";
const buildNumber = "Bucket";

export const buildUrl =
  window.location.origin.includes("techdisc.com") ||
  window.location.origin.includes(developSubstring)
    ? `https://storage.googleapis.com/techdisc-cdn/unity/${buildNumber}/Simulator/Build/Simulator`
    : "https://storage.googleapis.com/techdisc-cdn/Build/Simulator";

export const config = {
  loaderUrl: buildUrl + ".loader.js",
  dataUrl: buildUrl + ".data",
  frameworkUrl: buildUrl + ".framework.js",
  codeUrl: buildUrl + ".wasm",
  streamingAssetsUrl: "StreamingAssets",
  companyName: "TechDisc Inc.",
  productName: "TechDisc",
  productVersion: "0.1.0",
};

export const mobileBuildUrl =
  window.location.origin.includes("techdisc.com") ||
  window.location.origin.includes(developSubstring)
    ? `https://storage.googleapis.com/techdisc-cdn/unity/${buildNumber}/MobileSimulator/Build/MobileSimulator`
    : "https://storage.googleapis.com/techdisc-cdn/Build/MobileSimulator";
export const mobileConfig = {
  loaderUrl: mobileBuildUrl + ".loader.js",
  dataUrl: mobileBuildUrl + ".data",
  frameworkUrl: mobileBuildUrl + ".framework.js",
  codeUrl: mobileBuildUrl + ".wasm",
  streamingAssetsUrl: "StreamingAssets",
  companyName: "TechDisc Inc.",
  productName: "TechDisc",
  productVersion: "0.1.0",
};

export interface UnityInstance {
  SendMessage(service: "JavascriptHookManager", method: string, value: string): void;
}
